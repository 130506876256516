import React from 'react'
import Layout from '../components/Layout/Layout'
import { graphql } from 'gatsby'
import Service from '../components/Service/Service'

const PageTemplate = ({
  data: {
    strapiServices,
    strapiHomePage: { tiles },
    strapiCompanyInfo: { serviceHealthcareBox }
  }
}) =>
  <Layout>
    <Service
      {...strapiServices}
      icons={tiles}
      serviceHealthcareBox={serviceHealthcareBox}
    />
  </Layout>

export const query = graphql`
  query ServicePageTemplate($id: String!) {
     ...serviceHealthcareBoxFragment
    strapiServices(id: { eq: $id }) {
      ...serviceFragment
    }
    strapiHomePage {
      tiles {
        header
        href
        image {
          alternativeText
          url
          width
          height
        }
      }
    }
  }
`

export default PageTemplate
