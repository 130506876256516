import React from 'react'
import classNames from 'classnames'
import ServiceContentPart from './ServiceContentPart'
import { GatsbyImage } from 'gatsby-plugin-image'
import { GatsbyImageDataPropType, ServiceContentPartPropType } from '../propTypes'
import PropTypes from 'prop-types'

const getServiceRightSideClassName = (contentParts) => classNames(
  'service-right',
  {
    'service-right--centered': contentParts.length === 0
  },
)

const ServiceRightSide = ({ contentParts, alternativeText, gatsbyImageData }) =>
  <div className={getServiceRightSideClassName(contentParts)}>
    <div>
      {contentParts?.map((contentPart, index) =>
        <ServiceContentPart key={index} {...contentPart}/>)}
    </div>
    <div className='service-right__image'>
      <GatsbyImage
        alt={alternativeText}
        image={gatsbyImageData}
      />
    </div>
  </div>

export default ServiceRightSide

ServiceRightSide.propTypes = {
  contentParts: PropTypes.arrayOf(ServiceContentPartPropType),
  alternativeText: PropTypes.string,
  gatsbyImageData: GatsbyImageDataPropType,
}

ServiceRightSide.defaultProps = {
  contentParts: [],
  alternativeText: '',
  gatsbyImageData: {},
}
