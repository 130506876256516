import React from 'react'
import PropTypes from 'prop-types'
import { LinkButtonPropType, } from '../propTypes'
import { Link } from 'gatsby'
import { Controller, Scene } from 'react-scrollmagic'

const ServiceHealthcareBox = ({ header, description, button }) =>
  <Controller>
    <Scene
      classToggle='service-information-active'
      reverse={false}
      triggerHook='0.8'
    >
      <div className='service-information'>
        <div className='service-information__header'>{header}</div>
        <div className='service-information__description'>{description}</div>
        <Link
          className='service-information__button'
          to={button?.href}
        >
          {button?.label}
        </Link>
      </div>
    </Scene>
  </Controller>

export default ServiceHealthcareBox

ServiceHealthcareBox.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  button: LinkButtonPropType
}

ServiceHealthcareBox.defaultProps = {
  header: '',
  description: '',
  button: {}
}


