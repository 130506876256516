import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { ServiceIconsPropType } from '../propTypes'
import ServiceIcon from './ServiceIcon'

const ServiceIcons = ({ activeIndex, icons }) =>
  <div className='service-container__icons'>
    {icons.map((icon, index) =>
      <ServiceIcon
        {...icon}
        key={index}
        index={index}
        activeIndex={activeIndex}
      />
    )}
  </div>

export default ServiceIcons

ServiceIcons.propTypes = {
  activeIndex: PropTypes.number,
  icons: arrayOf(ServiceIconsPropType)
}

ServiceIcons.defaultProps = {
  activeIndex: 0,
  icons: []
}
