import React from 'react'
import ServiceContentPart from './ServiceContentPart'
import { ServiceContentPartPropType } from '../propTypes'
import PropTypes from 'prop-types'

const ServiceLeftSide = ({ contentParts }) =>
  <div className='service-left'>
    {contentParts?.map((contentPart, index) =>
      <ServiceContentPart key={index} {...contentPart}/>)}
  </div>

export default ServiceLeftSide

ServiceLeftSide.propTypes = {
  contentParts: PropTypes.arrayOf(ServiceContentPartPropType),
}

ServiceLeftSide.defaultProps = {
  contentParts: [],
}
