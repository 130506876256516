import React from 'react'
import SectionWrapper from '../SectionWrapper/SectionWrapper'
import ServiceIcons from './ServiceIcons'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ServiceIconsPropType,
  ServiceContentPartPropType,
  StrapiImageSharpPropType,
  ServiceHealthcareBoxPropType
} from '../propTypes'
import ServiceRightSide from './ServiceRightSide'
import ServiceLeftSide from './ServiceLeftSide'
import ServiceTop from './ServiceTop'
import ServiceHealthcareBox from './ServiceHealthcareBox'

const getServiceClassName = (rightSideContentParts) => classNames(
  'service-container__service',
  {
    'service-container__service--wide-bar': rightSideContentParts.length === 0
  },
)

const Service = ({
  serviceIndex,
  header,
  leftSideContentParts,
  rightSideContentParts,
  image,
  icons,
  serviceHealthcareBox
}) => {
  const { alternativeText, localFile: { childImageSharp: { gatsbyImageData } } } = image

  return <SectionWrapper className='service-wrapper'>
    <div className='service-container'>
      <ServiceIcons
        activeIndex={serviceIndex}
        icons={icons}
      />
      <div>
        <div className={getServiceClassName(rightSideContentParts)}>
          <ServiceTop
            header={header}
            alternativeText={alternativeText}
            gatsbyImageData={gatsbyImageData}
            activeIndex={serviceIndex}
            icons={icons}
          />
          <ServiceLeftSide contentParts={leftSideContentParts} />
          <ServiceRightSide
            alternativeText={alternativeText}
            gatsbyImageData={gatsbyImageData}
            contentParts={rightSideContentParts}
          />
        </div>
        <ServiceHealthcareBox {...serviceHealthcareBox} />
      </div>
    </div>
  </SectionWrapper>
}

export default Service

Service.propTypes = {
  serviceIndex: PropTypes.number,
  header: PropTypes.string,
  leftSideContentParts: PropTypes.arrayOf(ServiceContentPartPropType),
  rightSideContentParts: PropTypes.arrayOf(ServiceContentPartPropType),
  image: StrapiImageSharpPropType.isRequired,
  icons: PropTypes.arrayOf(ServiceIconsPropType),
  serviceHealthcareBox: ServiceHealthcareBoxPropType
}

Service.defaultProps = {
  serviceIndex: 0,
  header: '',
  leftSideContentParts: [],
  rightSideContentParts: [],
  icons: [],
  serviceHealthcareBox: {}
}


