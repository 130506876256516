import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { StrapiImagePropType } from '../propTypes'
import PropTypes from 'prop-types'

const getActiveIconClassName = (index, activeIndex) => classNames(
  'service-icon',
  {
    'service-icon-active': index === activeIndex
  },
)

const ServiceIcon = ({ href, index, activeIndex, image, header }) =>
  <Link
    to={href}
    className={getActiveIconClassName(index, activeIndex)}
  >
    <div className='service-icon__icon'>
      <img
        src={image?.url}
        alt={image?.alternativeText}
      />
    </div>
    <div className='service-icon__header'>
      {header}
    </div>
  </Link>

export default ServiceIcon

ServiceIcon.propTypes = {
  href: PropTypes.string,
  index: PropTypes.number,
  activeIndex: PropTypes.number,
  image: StrapiImagePropType,
  header: PropTypes.string
}

ServiceIcon.defaultProps = {
  href: '',
  index: 0,
  activeIndex: 0,
  image: {},
  header: '',
}
