import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { GatsbyImageDataPropType, ServiceIconsPropType } from '../propTypes'
import classnames from 'classnames'

const getHeaderClassName = (header) => classnames(
  'service-header-attached',
  header.length > 50 && 'service-header-attached--huge-text'
)

const ServiceTop = ({ alternativeText, gatsbyImageData, icons, header, activeIndex }) =>
  <>
    <div className='service-image-mobile'>
      <GatsbyImage
        alt={alternativeText}
        image={gatsbyImageData}
      />
    </div>
    <div className={getHeaderClassName(header)}>
      <img
        height={icons[activeIndex]?.image.height}
        width={icons[activeIndex]?.image.width}
        src={icons[activeIndex]?.image.url}
        alt={icons[activeIndex]?.image.alternativeText}
      />
      <div>{header}</div>
    </div>
  </>

export default ServiceTop

ServiceTop.propTypes = {
  alternativeText: PropTypes.string,
  gatsbyImageData: GatsbyImageDataPropType,
  icons: PropTypes.arrayOf(ServiceIconsPropType),
  header: PropTypes.string,
  activeIndex: PropTypes.number
}

ServiceTop.defaultProps = {
  alternativeText: '',
  gatsbyImageData: {},
  icons: [],
  header: '',
  activeIndex: 0
}
