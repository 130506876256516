import React from 'react'
import Paragraph from '../Paragraph/Paragraph'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { ListItemPropType } from '../propTypes'

const getContentPartClassName = (orangeHeadline, orangeBorder) => classNames(
  'service-content-part',
  {
    'with-orange-headline': orangeHeadline
  },
  {
    'with-orange-border': orangeBorder
  },
)

const ServiceContentPart = ({ header, headline, description, bulletPoints, orangeHeadline, orangeBorder }) =>
  <div className={getContentPartClassName(orangeHeadline, orangeBorder)}>
    {header &&
      <Paragraph
        className='service-header'
        text={header}
      />}
    {headline &&
      <Paragraph
        className='service-headline'
        text={headline}
      />}
    {description &&
      <Paragraph
        className='service-description'
        text={description}
      />}
    {bulletPoints?.length > 0 &&
      <ul className='service-bullet-points'>
        {bulletPoints.map((bulletPoint, index) =>
          <li key={index}>{bulletPoint?.text}</li>
        )}
      </ul>}
  </div>

export default ServiceContentPart

ServiceContentPart.propTypes = {
  header: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  bulletPoints: PropTypes.arrayOf(ListItemPropType),
  orangeHeadline: PropTypes.bool,
  orangeBorder: PropTypes.bool
}

ServiceContentPart.defaultProps = {
  header: '',
  headline: '',
  description: '',
  bulletPoints: [],
  orangeHeadline: false,
  orangeBorder: false,
}

